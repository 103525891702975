import './Footer.scss';

import React from 'react';
import { Link } from 'gatsby';

import { config } from 'config';

import logo from 'assets/images/harvard-easel-lab-logo.png';
import ContentWrapper from './ContentWrapper';

const Footer: React.FC = () => {
  return (
    <ContentWrapper>
      <div className="Footer">
        <div className="Footer-links no-print">
          <ul className="Footer-linkColumn-list">
            <li className="Footer-linkColumn-list-item">
              <Link className="Footer-linkColumn-link" to="/">
                Home
              </Link>
            </li>
            <li className="Footer-linkColumn-list-item">
              <Link className="Footer-linkColumn-link" to="/visual-tools/">
                Visual Tools
              </Link>
            </li>
            <li className="Footer-linkColumn-list-item">
              <Link className="Footer-linkColumn-link" to="/frameworks/">
                Frameworks
              </Link>
            </li>
            <li className="Footer-linkColumn-list-item">
              <Link className="Footer-linkColumn-link" to="/terms/">
                Terms
              </Link>
            </li>
          </ul>

          {config.stateStandardsEnabled && (
            <ul className="Footer-linkColumn-list">
              <li className="Footer-linkColumn-list-item">
                <Link className="Footer-linkColumn-link" to="/state-standards/">
                  State Standards
                </Link>
              </li>
              <li className="Footer-linkColumn-list-item">
                <Link
                  className="Footer-linkColumn-link"
                  to="/state-standards/alignment/"
                >
                  Alignment
                </Link>
              </li>
              <li className="Footer-linkColumn-list-item">
                <Link
                  className="Footer-linkColumn-link"
                  to="/state-standards/developmental-progression/"
                >
                  Developmental Progression
                </Link>
              </li>
            </ul>
          )}

          <ul className="Footer-linkColumn-list">
            <li className="Footer-linkColumn-list-item">
              <Link className="Footer-linkColumn-link" to="/about/">
                About SEL
              </Link>
            </li>
            <li className="Footer-linkColumn-list-item">
              <Link className="Footer-linkColumn-link" to="/work/">
                Our Work
              </Link>
            </li>
            <li className="Footer-linkColumn-list-item">
              <Link className="Footer-linkColumn-link" to="/team/">
                Our Team
              </Link>
            </li>
            <li className="Footer-linkColumn-list-item">
              <Link className="Footer-linkColumn-link" to="/methods/">
                Our Methods
              </Link>
            </li>
          </ul>
          <ul className="Footer-linkColumn-list">
            <li className="Footer-linkColumn-list-item">
              <Link className="Footer-linkColumn-link" to="/feedback/">
                Feedback
              </Link>
            </li>
            <li className="Footer-linkColumn-list-item">
              <Link className="Footer-linkColumn-link" to="/roadmap/">
                Roadmap
              </Link>
            </li>
            <li className="Footer-linkColumn-list-item">
              <Link className="Footer-linkColumn-link" to="/faq/">
                FAQs
              </Link>
            </li>
            <li className="Footer-linkColumn-list-item">
              <Link
                className="Footer-linkColumn-link"
                to="https://accessibility.huit.harvard.edu/digital-accessibility-policy"
              >
                Digital Accessibility Policy
              </Link>
            </li>
          </ul>
        </div>
        <a href="https://easel.gse.harvard.edu/" className="Footer-logo">
          <img
            src={logo}
            alt="Easel Labs, Harvard Graduate School of Education"
          />
        </a>
      </div>
      <div className="Footer-credit no-print">
        Design and Development by{' '}
        <a className="Footer-credit-link" href="https://friendsoftheweb.com/">
          Friends of The Web
        </a>
      </div>
    </ContentWrapper>
  );
};

export default Footer;
