import './Authentication.scss';

import React, { useState, useLayoutEffect } from 'react';

const credentials = {
  username: 'easellabs',
  password: 'beach unbroken cylinder'
};

interface Props {
  required: boolean;
  children: React.ReactNode;
}

const Authentication: React.FC<Props> = (props) => {
  const { required, children } = props;

  const [status, setStatus] = useState<
    null | 'unauthenticated' | 'authenticated' | 'error'
  >(null);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  useLayoutEffect(() => {
    setStatus(
      typeof document !== 'undefined' &&
        document.cookie.includes('autheticated=true')
        ? 'authenticated'
        : 'unauthenticated'
    );
  }, []);

  if (status === 'authenticated' || required === false) {
    return <>{children}</>;
  }

  if (status == null) {
    return null;
  }

  const submit = (event: React.SyntheticEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (
      username === credentials.username &&
      password === credentials.password
    ) {
      document.cookie = 'autheticated=true';
      setStatus('authenticated');
    } else {
      setStatus('error');
    }
  };

  return (
    <div className="Authentication">
      <form className="Authentication-form" onSubmit={submit}>
        {status === 'error' && (
          <div className="Authentication-error">
            Username or password are incorrect.
            <hr />
          </div>
        )}
        <label className="Authentication-label">
          Username:
          <input
            className="Authentication-input"
            type="text"
            value={username}
            onChange={(event) => {
              setUsername(event.currentTarget.value);
            }}
          />
        </label>
        <label className="Authentication-label">
          Password:
          <input
            className="Authentication-input"
            type="password"
            value={password}
            onChange={(event) => {
              setPassword(event.currentTarget.value);
            }}
          />
        </label>
        <button type="submit" className="Authentication-submitButton">
          Log In
        </button>
      </form>
    </div>
  );
};

export default Authentication;
