import './ContentWrapper.scss';

import React from 'react';

interface Props {
  children: React.ReactNode;
}

const ContentWrapper: React.FC<Props> = (props) => {
  const { children } = props;

  return <div className="ContentWrapper">{children}</div>;
};

export default ContentWrapper;
