import 'styles/application.scss';
import './Layout.scss';

import React from 'react';
import { Helmet } from 'react-helmet';

import Header from './Header';
import Footer from './Footer';
import Authentication from 'components/Authentication';
import ContentWrapper from './ContentWrapper';

interface Props {
  children: React.ReactNode;
  requiresAuthentication?: boolean;
  fullWidthContent?: boolean;
}

const Layout: React.FC<Props> = (props) => {
  const { children, requiresAuthentication, fullWidthContent = false } = props;

  return (
    <div>
      <Helmet>
        <title>Explore SEL</title>
        <meta property="og:title" content="Explore SEL" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="Explore SEL" />
        <meta
          property="og:description"
          content="Navigate the complex field of social and emotional learning."
        />
        <meta
          name="twitter:description"
          content="Navigate the complex field of social and emotional learning."
        />
        <html lang="en" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Explore SEL" />

        <meta
          property="og:image"
          content="http://exploresel.gse.harvard.edu/assets/exploresel.png"
        />
        <meta
          name="twitter:image"
          content="http://exploresel.gse.harvard.edu/assets/exploresel.png"
        />
        <meta property="og:image-width" content="1200" />
        <meta property="og:image-height" content="630" />
      </Helmet>

      <Authentication required={requiresAuthentication === true}>
        <Header />

        {fullWidthContent ? (
          <>{children}</>
        ) : (
          <ContentWrapper>
            <div className="Layout-content">{children}</div>
          </ContentWrapper>
        )}
        <Footer />
      </Authentication>
    </div>
  );
};

export default Layout;
