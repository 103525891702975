import './Header.scss';

import React from 'react';
import { Link } from 'gatsby';

import { config } from 'config';

import NavigationItem from './NavigationItem';

import harvardLogo from 'assets/images/harvard-logo.png';
import easelLabLogo from 'assets/images/harvard-easel-lab-logo.png';

/**
 * Site-wide header including the title and site navigation.
 */
const Header: React.FC = () => {
  return (
    <div className="Header">
      <div className="Header-banner no-print">
        <img
          className="Header-banner-logo"
          alt="Harvard University"
          src={harvardLogo}
        />
      </div>
      <div className="Header-main">
        <nav className="Header-navigation no-print">
          <h1 className="Header-title">
            <Link to="/">Explore SEL</Link>
          </h1>

          {config.stateStandardsEnabled ? (
            <div>
              <NavigationItem
                title="Frameworks"
                to="/visual-tools/"
                subNavigation={[
                  { title: 'Frameworks', to: '/visual-tools/' },
                  { title: 'View All', to: '/frameworks/' },
                  { title: 'Thesaurus', to: '/terms/' },
                  { title: 'Compare Domains', to: '/compare-domains/' },
                  { title: 'Compare Frameworks', to: '/compare-frameworks/' },
                  { title: 'Compare Terms', to: '/compare-terms/' }
                ]}
              />
              <NavigationItem
                title="State Standards"
                to="/state-standards/tools/"
                subNavigation={[
                  { title: 'State Standards', to: '/state-standards/tools/' },
                  { title: 'View All', to: '/state-standards/' },
                  { title: 'Alignment', to: '/state-standards/alignment/' },
                  {
                    title: 'Developmental Progression',
                    to: '/state-standards/developmental-progression/'
                  }
                ]}
              />

              <NavigationItem
                title="About"
                to="/about/"
                subNavigation={[
                  { title: 'About', to: '/about/' },
                  { title: 'Our Work', to: '/work/' },
                  { title: 'Our Team', to: '/team/' },
                  { title: 'Methods', to: '/methods/' }
                ]}
              />
            </div>
          ) : (
            <div>
              <NavigationItem
                title="Visual Tools"
                to="/visual-tools"
                subNavigation={[
                  { title: 'Visual Tools', to: '/visual-tools/' },
                  { title: 'Compare Domains', to: '/compare-domains/' },
                  { title: 'Compare Frameworks', to: '/compare-frameworks/' },
                  { title: 'Compare Terms', to: '/compare-terms/' }
                ]}
              />
              <NavigationItem title="Frameworks" to="/frameworks/" />
              <NavigationItem title="Thesaurus" to="/terms/" />
              <NavigationItem
                title="About"
                to="/about/"
                subNavigation={[
                  { title: 'About', to: '/about/' },
                  { title: 'Our Work', to: '/work/' },
                  { title: 'Our Team', to: '/team/' },
                  { title: 'Methods', to: '/methods/' }
                ]}
              />
            </div>
          )}
        </nav>
        <a href="https://easel.gse.harvard.edu/">
          <img
            className="Header-main-logo"
            alt="Easel Labs, Harvard Graduate School of Education"
            src={easelLabLogo}
          />
        </a>
      </div>
    </div>
  );
};

export default Header;
