import './NavigationItem.scss';

import React, { useState } from 'react';
import { Link } from 'gatsby';
import classnames from 'classnames';

interface Props {
  title: string;
  to: string;
  subNavigation?: { title: string; to: string }[];
}

/**
 * One top-level navigation item for use in the header. Secondary navigation,
 * appearing on hover, is defined by an optional set of titles and links.
 */

const NavigationItem: React.FC<Props> = (props) => {
  const { title, to, subNavigation } = props;
  const [subNavigationVisible, setSubNavigationVisible] = useState(false);

  return (
    <div
      className={classnames('NavigationItem', {
        'NavigationItem--hasSubnav': subNavigation != null
      })}
      onMouseEnter={() => setSubNavigationVisible(true)}
      onMouseLeave={() => setSubNavigationVisible(false)}
    >
      <Link to={to}>{title}</Link>

      {subNavigation && subNavigationVisible && (
        <div className="NavigationItem-subNavigation">
          {subNavigation.map((n, index) => (
            <Link
              className="NavigationItem-subNavigation-link"
              key={index}
              to={n.to}
              onClick={() => setSubNavigationVisible(false)}
            >
              {n.title}
            </Link>
          ))}
        </div>
      )}
    </div>
  );
};

export default NavigationItem;
